import IVButton from '@invideoio/uniformd/components/Button/index';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { DispatchContext, StateContext } from '@store/store';
import { useContext, useMemo, useRef, useState } from 'react';
import {
  AiFeature,
  PricingDuration,
  PricingPlanTopProps,
} from 'src/types/pricing.types';
import Badge from './Badge';
import DisplayPrice from './DisplayPrice';
import { GenerativeSecondsCounter } from './GenerativeSecondsCounter';
import {
  convertGenerativeCreditsToTime,
  getSeatsFromCounter,
  useAiCredits,
} from '../AiCredits';
import { sendRudderStackAnalyticEvent } from '@utils/analytics';
import {
  ChangeAddOnSeatsAnalytics,
  ChangeSeatsAnalytics,
} from 'src/types/analytics.types';

import { Popover, PopoverContent, PopoverTrigger } from '@ui/Popover';
import Image from 'next/image';
import { AssetsAppName, getWebAssetsUrl } from '@utils/web-assets';
import { BoostCounter } from './BoostCounter';

const planQuota = {
  free: {
    premium_minutes: 10,
    premium_media: 0,
  },
  plus: {
    premium_minutes: 50,
    premium_media: 80,
  },
  max: {
    premium_minutes: 200,
    premium_media: 320,
  },
  generative: {
    premium_minutes: 200,
    premium_media: 320,
  },
};

const PricingPlanTop = (
  props: PricingPlanTopProps & {
    billingText: JSX.Element;
    features: Array<AiFeature>;
  },
) => {
  const {
    planName,
    planKey,
    bannerText,
    planPrice,
    CTA,
    isYearlyDuration,
    theme = 'light',
    onClick,
    disabled = false,
    classNames = '',
  } = props;
  const dispatch = useContext(DispatchContext);
  const globalState = useContext(StateContext);

  const addonPopoverTriggerRef = useRef<HTMLButtonElement | null>(null);

  const [popoverOpen, setPopoverOpen] = useState(false);

  const popoverWidth = useMemo(() => {
    if (addonPopoverTriggerRef.current && popoverOpen) {
      return addonPopoverTriggerRef.current.clientWidth;
    }
    return 0;
  }, [addonPopoverTriggerRef, popoverOpen]);

  const { aiCredits, aiCreditsPrice } = useAiCredits({
    planKey,
    isYearlyDuration,
  });

  const { seats, aiCreditsCounter } = globalState;

  const billingCycle = useMemo(
    () => (isYearlyDuration ? PricingDuration.YEARLY : PricingDuration.MONTHLY),
    [isYearlyDuration],
  );

  const setSeats = (value: number) => {
    dispatch({ type: 'SET_SEATS', payload: { [planKey]: value } });
    const analyticsProperties: ChangeSeatsAnalytics['event_properties'] = {
      plan_price_id: planPrice[billingCycle].planId,
      seats: value,
      addon_plan_price_id:
        globalState.addOnSubscriptionPlans.generative[billingCycle].plan_id,
      addon_plan_seats: getSeatsFromCounter(aiCreditsCounter[planKey]),
    };
    sendRudderStackAnalyticEvent({
      event_name: 'change-seats',
      event_properties: analyticsProperties,
    });
  };

  const setAiCreditsCounter = (value: number) => {
    dispatch({ type: 'SET_AI_CREDITS_COUNTER', payload: { [planKey]: value } });
  };

  const baseGenerativeCredits =
    seats[planKey] *
    (props.features.find(feature => feature.name === 'ai_credits')?.quota || 0);
  const totalGenerativeCredits = baseGenerativeCredits + aiCredits;

  const handleDecrementAiCredits = () => {
    setAiCreditsCounter(aiCreditsCounter[planKey] - 1);

    const analyticsProperties: ChangeAddOnSeatsAnalytics['event_properties'] = {
      type: 'decrement',
      plan_price_id: planPrice[billingCycle].planId,
      seats: seats[planKey] ?? -1,
      addon_plan_price_id:
        globalState.addOnSubscriptionPlans.generative[billingCycle].plan_id,
      addon_plan_seats: getSeatsFromCounter(aiCreditsCounter[planKey] - 1),
    };

    sendRudderStackAnalyticEvent({
      event_name: 'change-addon-seats',
      event_properties: analyticsProperties,
    });
  };

  const handleIncrementAiCredits = () => {
    setAiCreditsCounter(aiCreditsCounter[planKey] + 1);

    const analyticsProperties: ChangeAddOnSeatsAnalytics['event_properties'] = {
      type: 'increment',
      plan_price_id: planPrice[billingCycle].planId,
      seats: seats[planKey] ?? -1,
      addon_plan_price_id:
        globalState.addOnSubscriptionPlans.generative[billingCycle].plan_id,
      addon_plan_seats: getSeatsFromCounter(aiCreditsCounter[planKey] + 1),
    };

    sendRudderStackAnalyticEvent({
      event_name: 'change-addon-seats',
      event_properties: analyticsProperties,
    });
  };

  return (
    <div
      className={ivclass(
        'iv-relative iv-h-[100%]',
        'iv-overflow-hidden',
        { 'iv-bg-grey-90': theme === 'dark' },
        { 'iv-bg-grey-0': theme === 'light' },
        classNames,
      )}
    >
      {bannerText && <Badge text={bannerText} />}
      <div className="iv-flex iv-flex-col iv-h-full">
        <div className="iv-flex-1">
          <div
            className={ivclass(
              'iv-pb-[12px] sm:iv-pb-[0px]',
              'iv-flex iv-justify-between',
            )}
          >
            <span
              className={ivclass(
                { 'iv-text-grey-0': theme === 'dark' },
                { 'iv-text-black-85': theme === 'light' },
                'iv-text-heading-s sm:iv-text-[30px] iv-leading-[45px]',
                'iv-font-bold',
              )}
            >
              {planName}
            </span>
          </div>

          <DisplayPrice
            theme={theme}
            planPrice={planPrice[billingCycle]}
            seats={seats[planKey]}
            aiCreditsPrice={aiCreditsPrice}
          />

          {planKey === 'free' ? (
            <div className={ivclass('iv-py-[47px] sm:iv-py-[0px]')}></div>
          ) : (
            <div className={ivclass('iv-py-20 ')}>
              <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
                <PopoverTrigger
                  ref={addonPopoverTriggerRef}
                  className="iv-flex iv-justify-between iv-items-center iv-bg-[#ededed] iv-w-full iv-p-12 data-[state=closed]:iv-rounded-lg data-[state=open]:iv-rounded-t-lg"
                >
                  <span className="iv-flex iv-flex-col iv-items-start iv-justify-start iv-gap-4">
                    <span className="iv-font-medium iv-text-body-xl">
                      ADD ONS
                    </span>
                    <span className="iv-text-body-l">
                      {seats[planKey] || 1}x Boost,{' '}
                      {convertGenerativeCreditsToTime(totalGenerativeCredits)}
                      &nbsp;Generative
                    </span>
                  </span>
                  <Image
                    src={`${getWebAssetsUrl(
                      '/pricing/arrow-down.svg',
                      AssetsAppName.COMMON,
                    )}`}
                    alt="crown"
                    width={20}
                    height={20}
                  />
                </PopoverTrigger>
                <PopoverContent
                  sideOffset={0}
                  className="iv-w-full iv-border iv-rounded-b-lg iv-border-grey-15 iv-bg-[#ededed] iv-border-t-[#cfcfcf] iv-border-t"
                  style={{
                    width: popoverWidth,
                  }}
                >
                  <GenerativeSecondsCounter
                    onDecrementClick={handleDecrementAiCredits}
                    onIncrementClick={handleIncrementAiCredits}
                    decrementDisabled={aiCreditsCounter[planKey] === 0}
                    incrementDisabled={aiCreditsCounter[planKey] === 9}
                    value={totalGenerativeCredits}
                  />
                  <BoostCounter
                    value={seats[planKey]}
                    decrementDisabled={seats[planKey] === 1}
                    incrementDisabled={seats[planKey] === 10}
                    onDecrementClick={() => setSeats(seats[planKey] - 1)}
                    onIncrementClick={() => setSeats(seats[planKey] + 1)}
                    quota={planQuota[planKey as keyof typeof planQuota]}
                  />
                </PopoverContent>
              </Popover>
            </div>
          )}
        </div>
        <IVButton
          className={ivclass(
            'iv-inline-flex iv-justify-center iv-items-center',
            'iv-cursor-pointer',
            'iv-rounded-full',
            'iv-px-15',
            'iv-h-52',
            'iv-w-full',
            'xl:iv-text-body-3xl lg:iv-text-body-3xl',
            'sm:iv-hidden',
            'iv-font-semibold',
            planKey === 'free'
              ? 'iv-bg-[#000]'
              : 'iv-bg-v30-primary-button-blue',
            {
              'iv-border iv-border-solid':
                CTA.backgroundColorClass === 'iv-bg-white-10' &&
                theme === 'light',
            },
            'iv-text-grey-0',
            disabled && 'iv-cursor-not-allowed',
          )}
          onClick={onClick}
          disabled={disabled}
        >
          {CTA.text}
        </IVButton>

        <div
          className={ivclass(
            'iv-text-body-m',
            'iv-text-center',
            'iv-my-5',
            'iv-h-[20px] sm:iv-hidden',
          )}
        >
          {props.billingText}
        </div>
      </div>
    </div>
  );
};

export default PricingPlanTop;
