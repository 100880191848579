import ivclass from 'clsx';
import PricingPlanTop from './PricingPlanTop';
import PricingPlanBottom from './PricingPlanBottom';
import { translate } from '@invideoio/web-shared/utils/common';
import { PlanProps, PricingDuration } from 'src/types/pricing.types';
import BillingText from './BilledText';
import { useContext } from 'react';
import { StateContext } from '@store/store';
import { useAiCredits } from '../AiCredits';

const PricingPlanCard = (props: PlanProps) => {
  const {
    planName,
    planKey,
    bannerText,
    planPrice,
    CTA,
    features,
    isYearlyDuration,
    theme = 'light',
    onClick,
    t = translate,
    disabled,
    goToPlanDetails,
  } = props;

  const globalState = useContext(StateContext);

  const billingCycle = isYearlyDuration
    ? PricingDuration.YEARLY
    : PricingDuration.MONTHLY;

  const { seats } = globalState;

  const { aiCreditsPrice } = useAiCredits({
    planKey,
    isYearlyDuration,
  });

  const billingText = (
    <BillingText
      billingCycle={billingCycle}
      planPrice={planPrice[billingCycle]}
      seats={seats[planKey]}
      aiCreditsPrice={aiCreditsPrice}
    />
  );

  return (
    <div
      className={ivclass(
        'iv-overflow-hidden',
        'iv-rounded-[25px] iv-border-2 iv-border-solid',
        'iv-flex xl:iv-flex-col lg:iv-flex-col md:iv-flex-wrap pricingcollapse:iv-flex-row sm:iv-flex-col',
        { 'iv-border-grey-5': !bannerText && theme === 'light' },
        { 'iv-border-transparent': !bannerText && theme === 'dark' },
        { 'iv-gradient-border iv-border-transparent': bannerText },
      )}
    >
      <PricingPlanTop
        planName={planName}
        planKey={planKey}
        planPrice={planPrice}
        CTA={CTA}
        isYearlyDuration={isYearlyDuration}
        bannerText={bannerText}
        theme={theme}
        onClick={onClick}
        disabled={disabled}
        t={t}
        classNames={'iv-pt-[32px] iv-px-[24px] iv-w-[320px] sm:iv-w-full'}
        billingText={billingText}
        features={features}
      />

      <PricingPlanBottom
        planKey={planKey}
        features={features}
        theme={theme}
        CTA={CTA}
        onClick={onClick}
        goToPlanDetails={goToPlanDetails}
        billingText={billingText}
      />
    </div>
  );
};

export default PricingPlanCard;
