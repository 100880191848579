import ivclass from '@invideoio/web-shared/Modules/IVClass';
import React from 'react';
import { PricingDuration } from 'src/types/pricing.types';

interface BillingTextProps {
  planPrice: {
    currency: string;
    price: number;
  };
  billingCycle: PricingDuration;
  aiCreditsPrice: number;
  seats?: number;
}

const BillingText = (props: BillingTextProps) => {
  const { currency, price } = props.planPrice;
  const { billingCycle, seats = 1 } = props;
  const billingText =
    billingCycle === PricingDuration.YEARLY
      ? `*Billed ${currency}${
          (price * seats + props.aiCreditsPrice) * 12
        } yearly`
      : `*Billed monthly until cancelled`;

  return price ? (
    <div className={ivclass('iv-font-medium', 'iv-text-black-50')}>
      {billingText}
    </div>
  ) : null;
};

export default BillingText;
