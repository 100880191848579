import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

class BaseAPI {
  private static http: AxiosInstance;
  private static instance: BaseAPI;

  private constructor() {
    BaseAPI.http = this.createAxiosInstance();

    BaseAPI.http.defaults.baseURL =
      process.env.NEXT_PUBLIC_PRO_API_HOST || 'https://pro-api.invideo.io';

    this.interceptRequest(BaseAPI.http);
    this.interceptResponse(BaseAPI.http);
  }

  private createAxiosInstance(): AxiosInstance {
    return Axios.create();
  }

  private interceptRequest(apiInstance: AxiosInstance) {
    apiInstance.interceptors.request.use(
      config => {
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );
  }

  private interceptResponse(apiInstance: AxiosInstance) {
    apiInstance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        return Promise.reject(error);
      },
    );
  }

  public static getHTTPInstance(): AxiosInstance {
    if (!this.instance) {
      this.instance = new BaseAPI();
    }
    return BaseAPI.http;
  }
}

const server: AxiosInstance = BaseAPI.getHTTPInstance();

const ProAPI = {
  withMetadata: (metadata: any) => {
    return {
      post: (url: string, data?: any, config?: AxiosRequestConfig) => {
        return server.post(url, { ...data, ...metadata }, config);
      },
      put: (url: string, data?: any, config?: AxiosRequestConfig) => {
        return server.put(url, { ...data, ...metadata }, config);
      },
    };
  },
  get: (url: string, config?: AxiosRequestConfig) => {
    return server.get(url, config);
  },
  post: (url: string, data: any, config?: AxiosRequestConfig) => {
    return server.post(url, data, config);
  },
  put: (url: string, data: any, config?: AxiosRequestConfig) => {
    return server.put(url, data, config);
  },
  delete: (url: string, config?: AxiosRequestConfig) => {
    return server.delete(url, config);
  },
};

export { ProAPI };
