import ivclass from '@invideoio/web-shared/Modules/IVClass';

const Badge = ({ text }: { text: string }) => {
  return (
    <div
      className={ivclass(
        'iv-gradient-border',
        'iv-absolute',
        'iv-top-[30px] lg:iv-top-[32px]',
        'iv-right-[-39%] md:iv-right-[-38%] lg:iv-right-[-37.5%]',
        'iv-w-full',
        'iv-transform iv-rotate-45',
        'iv-text-center iv-text-grey-0 iv-font-semibold',
        'iv-text-body-xl md:iv-text-body-l iv-m-body-xl',
      )}
    >
      {text}
    </div>
  );
};

export default Badge;
