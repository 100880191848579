export enum Dimension {
  LANDSCAPE = '16:9',
  SQUARE = '1:1',
  PORTRAIT = '9:16',
}

export enum AssetsAppName {
  COMMON = 'common',
  EDITOR = 'editor',
  LIHP = 'lihp',
  NONE = 'none',
  LANDING_PAGES = 'landing-pages',
}

export function getWebAssetsUrl(
  value: string,
  appName: AssetsAppName | undefined = AssetsAppName.COMMON,
) {
  switch (appName) {
    case AssetsAppName.COMMON:
      return `${process.env.NEXT_PUBLIC_SHARED_CDN_URL}${value}`;
    case AssetsAppName.EDITOR:
      return `${process.env.NEXT_PUBLIC_SHARED_CDN_URL}light/${value}`;
    case AssetsAppName.LIHP:
      return `${process.env.NEXT_PUBLIC_LIHP_CDN_URL}${value}`;
    case AssetsAppName.LANDING_PAGES:
      return `${process.env.NEXT_PUBLIC_CDN_URL}${value}`;
    case AssetsAppName.NONE:
    default:
      return `${value}`;
  }
}
