import { useContext, useMemo } from 'react';

import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { StateContext } from '@store/store';
import { replaceTripleBraces } from '@utils/pricing.utils';
import { convertGenerativeCreditsToTime } from '../AiCredits';

interface FeatureProps {
  icon: string;
  name: string;
  text: string;
  quota: number;
  addOnQuota?: number;
  planKey: string;
  help?: string;
  style?: string;
}

const Feature = (props: FeatureProps) => {
  const { text, name, icon, quota, planKey, addOnQuota, style = '' } = props;

  const globalState = useContext(StateContext);

  const { seats } = globalState;

  const finalQuota = useMemo(() => {
    if (name === 'ai_credits') {
      return convertGenerativeCreditsToTime(
        seats[planKey] * quota + (addOnQuota ?? 0),
      );
    }

    return seats[planKey] * quota + (addOnQuota ?? 0);
  }, [seats, planKey, name, quota, addOnQuota]);

  return (
    <div
      className={ivclass(
        'iv-flex iv-items-center',
        'iv-relative',
        'iv-pb-[9px]',
      )}
    >
      <span className={ivclass('iv-pr-9 lg:iv-pr-[11px]')}>
        <ImageComponent
          elementype="native"
          src={icon}
          loading="lazy"
          className={ivclass('iv-inline-block')}
        />
      </span>
      <div
        className={ivclass(
          'iv-grow',
          'iv-text-black-85',
          'iv-font-medium',
          planKey === 'free' && 'iv-opacity-50',
          style,
        )}
      >
        {replaceTripleBraces(text, { quota: finalQuota })}
      </div>
      {/* {help && platform === PlatformType.Desktop && (
        <div
          className={ivclass('iv-absolute iv-right-0 iv-top-0', 'sm:iv-hidden')}
        >
          <Tooltip content={help}>
            <ImageComponent
              elementype="native"
              src={`${assetsUrl}/pricing/info.svg`}
              loading="lazy"
              className={ivclass('iv-inline-block')}
            />
          </Tooltip>
        </div>
      )} */}
    </div>
  );
};

export default Feature;
