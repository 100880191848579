import ivclass from '@invideoio/web-shared/Modules/IVClass';

interface ToggleDurationButtonProps {
  toggleState: boolean;
  toggleHandler: Function;
  wrapperClass?: string;
}

const ToggleDurationButton = (props: ToggleDurationButtonProps) => {
  const { toggleState, toggleHandler, wrapperClass } = props;

  return (
    <div
      className={ivclass(
        'iv-relative',
        'iv-flex-col',
        'iv-justify-self-center',
        'iv-text-center',
        'iv-w-[212px] iv-mx-auto',
        wrapperClass,
      )}
    >
      <button
        onClick={() => toggleHandler(!toggleState)}
        className={ivclass(
          'iv-relative',
          'iv-p-3',
          'iv-bg-grey-5',
          'iv-border-none',
          'iv-rounded-[80px]',
          'iv-text-body-l',
        )}
      >
        <div
          className={ivclass(
            'iv-relative iv-flex iv-items-center iv-w-[208px] iv-h-[38px]',
            'iv-z-0',
            'iv-pt-5 iv-pb-6 iv-px-15 sm:iv-py-8',
          )}
        >
          <span
            className={ivclass(
              'iv-absolute',
              'iv-h-[39px]',
              'iv-top-0',
              'iv-rounded-[80px]',
              'iv-bg-grey-0',
              'iv-transition-all',
              'iv-ease-out',
              'iv-duration-300',
              'iv-top-[-1px]',
              toggleState
                ? 'iv-translate-x-[85px] iv-w-[128px] iv-left-[-4px]'
                : 'iv-translate-x-[5px] iv-w-[81px] iv-left-[-5px]',
              toggleState ? 'iv-z-[0]' : 'iv-z-[4]',
            )}
          />
          <span
            className={ivclass(
              'iv-absolute',
              'iv-h-[39px]',
              'iv-top-0',
              'iv-rounded-[80px]',
              'iv-transition-all',
              'iv-ease-out',
              'iv-duration-300',
              'iv-top-[-1px]',
              'iv-translate-x-[85px] iv-w-[128px] iv-left-[-4px]',
              'pricing-toggle',
              'iv-bg-grey-0',
              !toggleState && 'iv-opacity-0',
            )}
          >
            <span
              className={ivclass(
                'iv-bg-grey-0',
                'pricing-toggle-inner',
                'iv-transition-all iv-ease-out iv-duration-300 ',
              )}
            />
          </span>
          <span
            className={ivclass(
              'iv-w-[80px] iv-inline-block iv-mr-10',
              'iv-text-grey-100 iv-font-semibold',
              'iv-absolute iv-left-[-1px]',
              'iv-z-[4]',
            )}
          >
            Monthly
          </span>
          <span
            className={ivclass(
              'iv-w-[102px] iv-inline-block',
              'iv-text-grey-100 iv-font-semibold',
              'iv-absolute iv-right-[15px]',
              'iv-z-[4]',
            )}
          >
            <span className={ivclass('iv-text-grey-100 iv-font-semibold')}>
              Yearly
            </span>
            <span className="iv-text-black-20"> · </span>
            <span
              className={ivclass(
                'iv-gradient-blue-deepblue-raspberry iv-text-clip',
                'clip-text',
              )}
            >
              20% off
            </span>
          </span>
        </div>
      </button>
    </div>
  );
};

export default ToggleDurationButton;
