import { useContext, useEffect, useState } from 'react';
import { DispatchContext, StateContext } from '@store/store';
import {
  serializeAiPlans,
  serializeAiPlansFeatures,
} from '@utils/pricing.utils';
import { ProAPI } from '@lib/ProAPI';

function useAIPricing(isIntersecting: boolean) {
  const dispatch = useContext(DispatchContext);

  const globalState = useContext(StateContext);

  const [hasPlanFetched, setHasPlanFetched] = useState(false);

  useEffect(() => {
    if (isIntersecting && !hasPlanFetched) {
      const planUrl = '/api/plans/detailed';

      ProAPI.get(planUrl)
        .then(response => {
          const newplanData = serializeAiPlans(
            response.data?.plans,
            globalState.aiPlanData,
          );

          const features = serializeAiPlansFeatures(
            response.data?.plans,
            globalState.aiPlanData,
            {},
          );

          dispatch({
            type: 'SET_AI_PLAN_DATA',
            payload: newplanData.sort((a, b) => a.sequence - b.sequence),
          });
          dispatch({ type: 'SET_AI_PLAN_FEATURES', payload: features });
          dispatch({
            type: 'SET_ADD_ON_SUBSCRIPTION_PLANS',
            payload: response.data?.addon_subscription_plans,
          });

          setHasPlanFetched(true);
        })
        .catch(error => {
          console.error(error);
          setHasPlanFetched(true);
          dispatch({ type: 'SET_AI_PLAN_DATA', payload: [] });
        });
    }
  }, [isIntersecting, dispatch, globalState.aiPlanData, hasPlanFetched]);

  return { hasPlanFetched };
}

export default useAIPricing;
